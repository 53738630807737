import React, {useEffect, useRef, useState} from "react";
import {Player} from "@lottiefiles/react-lottie-player";
import Video from "./Video";
import Header from "./Header";
import "../App.scss"
import Footer from "./Footer";
import Carousel from "./Carousel";
import Partners from "./Partners";
import Cookies from "./Cookies";
import haut from '../assets/haut.webp'
import bas from '../assets/bas.webp'
import fond from '../assets/fondTel2.png'
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

const links = [
    { href: '/materiel', label: 'Matériel' },
    { href: '/logiciel', label: 'Logiciel' },
    { href: '/telephonie', label: 'Téléphonie' },
    { href: '/it', label: 'IT management' },
    { href: '/contact', label: 'Contact' },
    {href: '/', label: 'Accueil'},
    {href: '/univers', label: 'L\'univers d\'azzu'},
    {href: '/conditions', label: 'conditions'}
]


export default function Home() {

    const outil = useRef(null);
    const lottie1 = useRef(null);
    const gain = useRef(null);
    const activite = useRef(null);
    const solution = useRef(null);
    const parc = useRef(null);
    const productivite = useRef(null);

    useGSAP(() => {
        gsap.from(outil.current, {
            opacity: 0,
            x: -200,
            scrollTrigger: {
                trigger: outil.current,
                start: "top 50%",
                end: "top 100px",
                scrub: true,
            },
            ease: "power3.out",

        });

        gsap.from(lottie1.current, {
            opacity: 0,
            x: 200,
            scrollTrigger: {
                trigger: lottie1.current,
                start: "top center",
                end: "top 100px",
                scrub: true,
            },
            ease: "power3.out",

        });

        gsap.from(gain.current, {
            opacity: 0,
            x: 100,
            scrollTrigger: {
                trigger: gain.current,
                start: "top center",
                end: "top 100px",
                // scrub: 1,
            },
            ease: "power3.out",
            duration: 1,
        });

        gsap.from("#slogan h2", {
            opacity: 0,
            duration: 1,
            scrollTrigger: {
                trigger: "#slogan h2",
                start: "top 80%",
                end: "top 20%",
                // scrub: true,
            },
        });

        // Animation pour les textes #activite et #parc (slide par la gauche)
        gsap.from(["#activite", "#parc"], {
            x: -200,
            opacity: 0,
            duration: 5,
            scrollTrigger: {
                trigger: "#slogan",
                start: "top 80%",
                end: "top 20%",
                // scrub: true,
            },
        });

        // Animation pour les textes #solution et #productivite (slide par la droite)
        gsap.from(["#solutions", "#productivite"], {
            x: 200,
            opacity: 0,
            duration: 4,
            scrollTrigger: {
                trigger: "#slogan",
                start: "top 80%",
                end: "top 20%",
                // scrub: true,
            },
        });

        // Animation pour les images (slide par le bas)
        gsap.from("#slogan img", {
            y: 200,
            opacity: 0,
            duration: 1,
            scrollTrigger: {
                trigger: "#slogan img",
                start: "top 80%",
                end: "top 20%",
                // scrub: true,
            },
        });

    })
    return (
        <div className="body">
            <Header/>
            <Video/>
            <div className={`w-full md:h-100vh border-gray-100 rounded-xl flex flex-col md:flex-row justify-center  relative`}>
                <img src={haut} className="hidden sm:block absolute top-0 left-0 h-[500px] lg:h-[900px] md:h-[800px] w-full" alt="fond"/>

                <div  ref={outil} id="anchor" className={`md:my-10 w-full md:w-1/2 lg:w-1/3 flex flex-col space-y-4 lg:space-y-12 justify-center pt-12 md:pt-0`}>
                    <p  className={`w-10/12 text-sm mx-auto text-center md:text-2xl md:leading-9 font-semibold`}>Forts de plus de 15 ans d’expérience dans le domaine, nous sommes convaincus que l’informatique ne doit pas être
                        une source de stress pour les entreprises, mais plutôt <p className="py-3 text-bleu-nuit text-xl md:text-3xl rounded-xl"><em className="not-italic p-1 font-bold">un outil efficace pour leur développement.</em></p></p>
                    <div className="mx-auto  lg:my-12 xl:pt-5">
                        <a id="button2" href="../univers" className=" bg-bleu-nuit rounded-xl m-auto py-4 px-8 lg:px-12 lg:py-8 text-white font-bold text-center lg:text-xl">Découvrez notre univers</a>
                    </div>
                </div>
                <div ref={lottie1} className={`mt-32 md:mt-[100px] lg:mt-[50px]`}>
                    <Player  className="m-auto h-72 w-72 md:w-72 md:h-72 lg:w-96 lg:h-96 xl:h-[600px] xl:w-[600px] "
                             autoplay
                             loop
                             src='https://assets8.lottiefiles.com/packages/lf20_itbfc45d.json'
                        // style={{ height: '200px', width: '200px' }}
                    >
                    </Player>
                </div>
            </div>
            <Carousel/>
            <div className="flex flex-col-reverse m-auto md:w-3/4 lg:flex-row relative md:py-24">
                <div  className={`flex flex-row justify-center my-auto `}>
                    <Player className="my-auto h-72 w-72 md:w-96 md:h-96 xl:h-[600px] xl:w-[600px]"
                            autoplay
                            loop
                            src='https://lottie.host/0ad7a3dd-61c0-4fc2-ba5d-58812effa80e/LLIA4XtXmz.json'
                        // style={{ height: '200px', width: '200px' }}
                    >
                    </Player>
                </div>

                <div ref={gain} className={`w-full md:w-3/4 m-auto md:py-12 xl:py-12`}>
                    <h1  className={`text-center text-xl md:text-2xl  text-bleu-fonce w-full py-16 font-bold px-5 `}>Gagner en productivité n'a jamais été aussi facile !</h1>
                    <p className={`text-sm w-full text-center md:text-xl md:text-left px-5 md:leading-10 md:tracking-wide  font-semibold`}>À une époque où tout est interconnecté et en constante évolution, Azzu Informatique vous propose
                        un partenariat durable qui vous permettra de vous concentrer pleinement sur votre activité.
                        Pendant que vous vous consacrez à votre domaine d’expertise, nous prenons en charge la gestion de votre infrastructure
                        informatique. Ainsi, vous bénéficiez de la tranquillité d’esprit en sachant que votre outil informatique est entre de
                        bonnes mains. Azzu-informatique a forcément une solution adaptée à vos besoins. contactez nous !</p>
                </div>

            </div>
            <div id="slogan" className="md:mt-28 xl:mt-56 h-[300px] sm:h-[500px] relative mt-10 sm:my-20 mb-48">
                <img src={bas} className="hidden sm:block absolute sm:h-[700px] md:h-[600px] lg:h-[700px] w-full -top-32 lg:-top-64 left-0 -z-10" alt="fond"/>
                <h2 className="text-xl text-center text-bleu-fonce p-4 md:text-2xl text-jaune2">Ensemble, changeons votre vision de l'informatique d'entreprise</h2>
                <div className="w-full  md:w-full 2xl:w-10/12 relative m-auto ">

                    {/*md 768px*/}
                    <img className="sm:hidden md:block xl:hidden 2xl:hidden  absolute right-0  -bottom-12 hidden" height={400} width={200} src="https://i.ibb.co/C6XMsQ7/anthony.webp" alt=""/>
                    <img className="sm:hidden md:block xl:hidden 2xl:hidden absolute left-0 -bottom-12  hidden" height={400} width={200} src="https://i.ibb.co/sHdvSj2/r-mi-2.webp" alt=""/>


                    <img className="sm:hidden md:hidden xl:block 2xl:hidden  absolute right-4  -bottom-12 hidden" height={500} width={250} src="https://i.ibb.co/C6XMsQ7/anthony.webp" alt=""/>
                    <img className="sm:hidden md:hidden xl:block 2xl:hidden absolute left-4 -bottom-12  hidden" height={500} width={250} src="https://i.ibb.co/sHdvSj2/r-mi-2.webp" alt=""/>

                    {/**/}
                    <img className=" hidden md:hidden 2xl:block absolute  left-8 bottom-0" height={610} width={410} src="https://i.ibb.co/sHdvSj2/r-mi-2.webp" alt=""/>
                    <img className="hidden md:hidden 2xl:block absolute right-8 bottom-0" height={610} width={410} src="https://i.ibb.co/C6XMsQ7/anthony.webp" alt=""/>
                    <div className="w-full md:w-3/5 2xl:w-1/3 m-auto relative p-4">
                        <p ref={activite} id="activite" className={`text-left text-jaune font-bold text-base md:text-lg xl:text-xl `}>Vous vous concentrez sur votre activité</p>
                        <p ref={solution} id="solutions" className={`text-right font-bold text-base text-jaune2 md:text-lg xl:text-xl `} >Nous déployons des solutions adaptées</p>
                        <p ref={parc} id="parc" className={`text-left text-jaune font-bold text-base md:text-lg xl:text-xl `}>Nous assurons la gestion de votre parc</p>
                        <p ref={productivite} id="productivite" className={`text-right font-bold text-base text-jaune2 md:text-lg xl:text-xl `}>Vous gagnez en productivité</p>

                    </div>
                    <div id="button2" className="py-8 flex flex-row justify-center m-auto relative">
                        <a id="button" className=" border-2 shadow-2xl hover:shadow-xl shadow-bleu-ciel border-bleu-ciel bg-jaune2 rounded-2xl m-auto py-6 px-10 text-white font-bold text-center" href="tel:+33632513175">Contactez nous</a>
                    </div>
                </div>
            </div>
            <Partners/>
            <Footer/>
            <Cookies/>

        </div>
    )
}
