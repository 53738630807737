import React from 'react';
import {Link} from "react-router-dom";

const links = [
    { href: '/materiel', label: 'Matériel' },
    { href: '/logiciel', label: 'Logiciel' },
    { href: '/telephonie', label: 'Téléphonie' },
    { href: '/it', label: 'IT management' },
    { href: '/contact', label: 'Contact' },
    {href: '/', label: 'Accueil'},
    {href: '/univers', label: 'L\'univers d\'azzu'},
    {href: '/conditions', label: 'Conditions'}
]

export default function Footer()  {

    return (
        <footer className="bg-bleu-nuit h-auto flex justify-between w-full text-yellow-300 bottom-0 text-jaune2" id="footer">
            <ul className="flex flex-col p-4 text-left md:text-base text-xs my-auto">
                <li>
                    <a href="/">Accueil</a>
                </li>
                <li>
                    <a href="./it">It management</a>
                </li>
                <li>
                    <a href="./logiciel">Logiciels et sécurité</a>
                </li>

            </ul>
            <ul className="flex flex-col justify-center align-middle p-4 text-xs">
                <li>
                    <Link to="/">
                        <img className="mx-auto" src="https://i.ibb.co/vVd9HWH/logo.png" alt="Logo"
                             height='150px'
                             width='150px'
                        />
                    </Link>
                </li>
                {/*<li className="text-center">*/}
                {/*    <a className='text-[12px] text-center'>DB were here</a>*/}
                {/*</li>*/}

            </ul>
            <ul className="flex flex-col md:text-base p-4 text-right my-auto text-xs">
                <li>
                    <a href="./materiel">Matériel</a>
                </li>
                <li>
                    <a href="./univers">Notre univers</a>
                </li>
                <li>
                    <a href="./conditions">Termes et conditions</a>
                </li>
            </ul>
        </footer>
    );
}

