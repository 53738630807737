import React, {useEffect} from 'react';
import Header from "./Header";
import Footer from "./Footer";
import security from '../assets/Securité.webp'
import haut from '../assets/haut.webp'
import logiciels from '../assets/logiciels.webp'
import quote2 from "../assets/citation2.webp";
import quote from "../assets/citation.webp";
import bas from '../assets/bas.webp'
import "../App.scss"
import { isMobile } from 'react-device-detect';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { TextPlugin } from "gsap/TextPlugin";
import { gsap } from "gsap";
import {useGSAP} from "@gsap/react";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, TextPlugin);





export default function Software()  {
    React.useEffect(() => {
        console.log(isMobile)
        if (!isMobile) {
            const tl = gsap.timeline();
            tl.from('.relative #fond', {opacity: 0, y: -400, duration: 0.5})
                .from("#logiciel h2", {opacity: 0, x: -200, y: -200, duration: 0.5})
                .from("#logiciel p", {opacity: 0, x: -100, y: -100, duration: 0.5})
                .from("#logiciel #image", {opacity: 0, duration: 1});

            gsap.from("#suite h2", {
                opacity: 0,
                x: -200,
                scrollTrigger: {
                    trigger: "#suite h2",
                    start: "top 90%",
                    end: "top 100px",
                },
                ease: "power3.out",
            });

            gsap.from("#suite p", {
                opacity: 0,
                x: -400,
                scrollTrigger: {
                    trigger: "#suite p",
                    start: "top 90%",
                    end: "top 100px",
                },
                ease: "power3.out",
            });

            gsap.from("#security h2", {
                opacity: 0,
                x: 400,
                scrollTrigger: {
                    trigger: "#security h2",
                    start: "top 90%",
                    end: "top 300px",
                },
                ease: "power3.out",
            });

            gsap.from("#security p", {
                opacity: 0,
                x: 500,
                scrollTrigger: {
                    trigger: "#security p",
                    start: "top 90%",
                    end: "top 100px",
                },
                ease: "power3.out",
            });

            gsap.from("#button", {
                opacity: 0,
                rotateY: 180,
                scrollTrigger: {
                    trigger: "#button",
                    start: "top 90%",
                    end: "top 100%",
                },
                ease: "power3.out",
                duration: 1.5,
            });
        }
    } ,[])





    return (
        <div className="body h-full w-full">
            <Header/>
            <div className="relative h-auto">
                <img id="fond" src={haut}
                     className="hidden sm:block absolute top-0  left-0 -z-10 md:h-[900px] lg:h-[650px] xl:h-[700px] w-full m-auto"
                     alt=""/>
                <div id="logiciel" className="w-10/12 xl:w-1/2 md:mb-48 flex flex-col lg:justify-center lg:flex-row m-auto pt-12">
                    <div>
                        <h2 className="text-2xl md:text-4xl py-2 text-center text-bleu-ciel">Bienvenue dans le Monde des Solutions
                            Numériques Avancées</h2>
                        <p className="xl:w-full py-3 text-sm md:text-xl text-justify leading-9  font-semibold">
                            Chez Azzu-informatique, nous vous proposons des solutions logicielles innovantes pour
                            améliorer votre productivité et sécuriser vos données. Notre gamme de services inclut la
                            gestion
                            informatique, la sécurité contre les cybermenaces, des outils comme Microsoft 365, et des
                            stratégies de cloud computing. Notre mission : répondre précisément à vos besoins avec des
                            solutions
                            personnalisées, optimisant la gestion informatique, la sécurité du réseau et le télétravail.
                            Découvrez comment nos services peuvent booster votre entreprise.
                        </p>
                    </div>
                </div>

                <div id="suite" className="w-10/12 xl:w-8/12 flex flex-col lg:flex-row items-center justify-center space-y-8 mb-24 md:mt-96 lg:mt-[400px] xl:mt-[300px] mx-auto space-x-12">
                    <div>
                        <h2 className="text-2xl md:text-4xl py-2 text-bleu-ciel">Logiciel</h2>
                        <p className="  py-3 text-sm md:text-xl text-justify leading-9  font-semibold">
                            Notre gamme de solutions logicielles booste la gestion informatique et la productivité. Profitez de Microsoft 365 pour une efficacité accrue et de notre service RMM pour la surveillance à
                            distance, anticipant les problèmes informatiques. Expertise en cloud computing pour plus de flexibilité et d'évolutivité. Nos outils favorisent le travail d'équipe et la coopération.
                            De plus, nos logiciels supportent efficacement le télétravail, assurant la continuité des opérations, partout et à tout moment.
                        </p>
                    </div>

                    <img id="image" src={logiciels} className="w-96 h-96 m-auto" alt=""/>
                </div>
                <div
                    className="w-10/12 xl:w-8/12 flex flex-col lg:flex-row-reverse items-center justify-center space-y-8 my-24 md:mb-72 lg:my-36 xl:my-56 mx-auto space-x-12">

                    <div id="security">
                        <h2 className="text-2xl md:text-4xl py-2 text-bleu-ciel text-right">Sécurité</h2>
                        <p className=" py-3 text-sm md:text-xl text-justify  font-semibold">
                            Nous priorisons la sécurité face aux attaques de ransomware et autres menaces cybernétiques.
                            Notre infrastructure de sécurité solide, gérée par des experts,
                            offre surveillance constante et détection précoce des menaces pour protéger vos données
                            sensibles. En cas d'attaque, nos procédures d'urgence assurent une
                            récupération rapide et minimisent les perturbations, gardant vos données sécurisées contre
                            les cybercriminels.
                        </p>
                    </div>
                    <img src={security} className="w-[24rem] h-[24rem]" alt=""/>

                </div>
                <div
                    className="w-10/12 3xl:w-8/12 flex flex-col md:flex-row mx-auto md:space-x-2 lg:space-x-24 justify-center items-center">
                    <img src={bas}
                         className='hidden md:block absolute bottom-0 left-0 w-full md:h-[980px] lg:h-[850px] xl:h-|700px] 2xl:h-[600px]  -z-10'
                         alt=""/>
                    <div className="">
                        <h2 className="text-2xl md:text-4xl py-2 text-bleu-ciel">Sauvegarde</h2>
                        <p className="text-sm md:text-white md:text-xl text-justify  font-semibold">
                            La méthode 3-2-1 est au cœur de notre solution de sauvegarde : trois copies de vos données,
                            dont deux locales sur des supports différents et une hors site,
                            garantissent la protection contre toute défaillance. Notre système automatisé s'intègre à
                            votre environnement informatique, assurant une sauvegarde fiable avec
                            gestion de la rotation et tests de restauration réguliers pour une sécurité des données sans
                            souci.
                        </p>
                    </div>
                    <div className="bg-gray-200 h-auto text-justify mx-8 md:my-auto rounded-lg p-5 relative">
                        <img src={quote2} className="absolute w-12 h-12  -top-4 left-0" alt=""/>
                        <p className="text-bleu-nuit font-extrabold text-base lg:text-lg ">
                            Forts de notre expertise approfondie et de notre approche méthodique, nous procédons à un
                            diagnostic précis afin de résoudre efficacement vos problèmes
                            informatiques, réduisant ainsi au minimum les temps d'indisponibilité et vous permettant de
                            reprendre vos activités sans délai</p>
                        <img src={quote} className="absolute w-12 h-12 -bottom-4 right-0" alt=""/>
                    </div>
                </div>
                <div className="py-16 flex flex-row justify-center mx-auto ">
                    <a id="button"
                       className=" border-2 shadow-2xl hover:shadow-xl shadow-bleu-ciel border-bleu-ciel bg-jaune2 rounded-2xl m-auto py-6 px-10 text-white font-bold text-center"
                       href="tel:+33632513175">Contactez nous</a>
                </div>
            </div>
            <Footer/>
        </div>

    );
}


