import React, {useEffect, useRef} from 'react';
import "../animations/photo.js"
import Header from "./Header";
import Footer from "./Footer";
import haut from '../assets/haut.webp'
import bas from '../assets/bas.webp'
import equipe from '../assets/equipe.webp'
import {useGSAP} from "@gsap/react";
import {gsap} from "gsap";


export default function Universe() {

    const photoRef = useRef(null);
    const containerRef = useRef(null);
    const glareRef = useRef(null);
    const bleu = useRef(null);
    const rouge = useRef(null);

    useEffect(() => {
        const handleMouseMove = (e) => {
            let xAxis = (window.innerWidth / 2 - e.pageX) / 10;
            let yAxis = (window.innerHeight / 2 - e.pageY) / 10;

            let rect = glareRef.current.getBoundingClientRect();
            const xMouse =  (e.clientX - rect.left) * 100 / rect.width;
            const yMouse =  (e.clientY - rect.top) * 100 / rect.height;
            //écris moi une fonction qui génère un nombre aléatoire entre 0 et 10, qui variera en fonction de la position de la souris
            const random = (min, max) => { return Math.floor(Math.random() * (max - min + 1)) + min; }


            photoRef.current.style.transform = `rotateY(${ - xAxis}deg) rotateX(${ - yAxis}deg)`;
            bleu.current.style.transform = `translate(${xAxis / 10}px, ${yAxis / 10}px)`;
            glareRef.current.style.backgroundImage = `radial-gradient(farthest-corner circle at ${xMouse}% ${yMouse}%, hsl(0,0%,80%), hsla(0,0%, 74.9%,0.25) 30%, hsl(0,0%,21.6%) 130%)`;
        };

        const handleMouseEnter = () => {
            photoRef.current.style.transition = "none";
        };

        const handleMouseLeave = () => {
            const photo = photoRef.current;
            photo.style.transition = "all 0.7s ease";
            photo.style.transform = `rotateX(0deg) rotateY(0deg)`;
        };

        const container = containerRef.current;
        container.addEventListener("mousemove", handleMouseMove);
        container.addEventListener("mouseenter", handleMouseEnter);
        container.addEventListener("mouseleave", handleMouseLeave);

        return () => {
            container.removeEventListener("mousemove", handleMouseMove);
            container.removeEventListener("mouseenter", handleMouseEnter);
            container.removeEventListener("mouseleave", handleMouseLeave);
        };
    }, []);
    useGSAP(() => {

        gsap.from("#button", {
            opacity: 0,
            rotateY: 180,
            scrollTrigger: {
                trigger: "#button",
                start: "top 90%",
                end: "top 100%",
                // scrub: 1,
            },
            ease: "power3.out",
            duration: 1.5,

        });

        gsap.from("#Remi", {
            opacity: 0,
            rotateY: 180,
            scrollTrigger: {
                trigger: "#remi",
                start: "top 90%",
                end: "top 100px",
            },
            ease: "power3.out",
            duration: 1.5,
        });


        gsap.from("#remi2", {
            opacity: 0,
            duration: 5.5,
            scrollTrigger: {
                trigger: "#remi2",
                start: "top 90%",
                end: "top 100px",
            },
            ease: "power3.out",

        });

        gsap.from("#equipe", {
            opacity: 0,
            rotateX: 180,
            duration: 0.8,
            scrollTrigger: {
                trigger: "equipe",
                start: "top 90%",
                end: "top 100px",
            },
            ease: "power3.inOut",

        });
    })


    return (

        <div className="body">
            <Header/>
            {/* cette div est le contenant  général de la page*/}
                {/*première partie, notre histoire*/}
                <div className="w-full flex flex-col-reverse lg:flex-row justify-center relative items-center m-auto pt-12">
                    {/*fond, qui représente des formes géométriques de couleur. le dimensionnement est très complexe à adapter pour qu'il couvre bien tout le texte de la partie*/}
                    <img src={haut} className="hidden md:block absolute top-0 left-0 -z-10  w-full lg:h-[1200px] xl:h-[900px] md:h-[1000px]" alt=""/>
                    <div className="w-8/12 md:w-10-12 lg:w-1/2  flex flex-row justify-center items-center m-auto my-8">
                        {/*photo du propriétaire du site avec un petit effet de hover. qui montre en filigrane plusieurs photos de lui mais à la couleur différente*/}
                        <div ref={containerRef} id="container" className="md:w-3/4 my-auto w-full h-auto relative">
                            <img  ref={photoRef} className="m-auto"
                                 src="https://i.ibb.co/Y4g10Nc/R-mi-fond-transparent-1.png" height="100%"
                                 width="100%" alt="Rémi" id="Remi"
                            />
                            <img ref={bleu} src="https://i.ibb.co/3M55c2f/R-mi-color-1.png" alt="rémi bleu"
                                 id="RemiBleu"/>
                            <img ref={rouge} src="https://i.ibb.co/WPddWVT/R-mi-color-2.png" alt="rémi rouge"
                                 id="RemiRouge"/>
                            <div ref={glareRef} id="glare"></div>
                        </div>
                    </div>
                    <div className="lg:w-1/2  mx-auto ">
                        <h2 className="md:text-4xl text-2xl text-bleu-ciel text-center m-auto">Notre histoire</h2>
                        <p className="w-10/12 pt-10 text-base m-auto text-justify md:text-xl md:text-justify leading-9 lg:leading-3 font-semibold">
                            En tant que professionnels chevronnés dans le domaine de l’informatique, nous avons acquis
                            une riche expérience tout au long de nos 25 années de travail combinées,
                            expérience qui a été le moteur de la création de notre entreprise.

                            Au cours de notre parcours professionnel, nous avons été sensibilisés à l’importance
                            d’apporter un soutien complet à nos clients. Nous avons
                            constaté que pour répondre de manière optimale à leurs besoins, il est impératif de
                            collaborer étroitement avec eux, en prenant le temps de comprendre et
                            d’analyser leurs besoins et leurs attentes. Dans cette optique, nous considérons que
                            l’établissement d’une relation de confiance est fondamental.
                        </p>
                    </div>
                </div>
                <div className="relative lg:mt-96">
                    <div className="mx-auto w-full md:flex md:flex-row md:justify-start mt-8 mb-12 lg:mb-48 lg:mt-64">
                        <div className="w-full flex flex-col-reverse my-auto lg:w-10/12 pt-5">
                            {/*zone de texte avec titre*/}
                            <div className="w-10/12 md:w-1/2 mx-5 lg:mx-auto lg:mr-96">
                                <h2 className="md:text-4xl text-2xl text-bleu-ciel m-auto text-center">Nos convictions</h2>
                                <p className="md:pt-10 text-base text-justify md:text-left md:text-xl leading-9 lg:m-auto  font-semibold">
                                    Nous sommes convaincus que l’informatique d’entreprise doit être abordée d’une
                                    manière
                                    différente. Nous refusons de suivre le modèle traditionnel de vente pure
                                    et simple de produits ou services génériques.
                                    Au contraire, nous sommes animés par la volonté de fournir des solutions sur mesure,
                                    en
                                    prenant en compte les besoins spécifiques de chaque client.
                                    Nous croyons fermement à l’importance de l’information et de la compréhension mutuelle.
                        Nous nous engageons à expliquer clairement les différentes options disponibles,
                        en évaluant objectivement les avantages et les inconvénients de chaque choix.
                        Notre objectif est de vous accompagner dans votre prise de décision, en vous conseillant
                        de manière éclairée et en vous aidant à comprendre les implications techniques.
                    </p>
                </div>
                {/*images qui s'affichent en absolu. si tu veux me proposer une position staique avec un réglage au niveau des marges, tente*/}
                <img id="remi2" className="absolute right-24 top-36 hidden lg:block lg:top-12 lg:right-12 xl:right-40" height={600} width={400} src="https://i.ibb.co/kXG7Dnw/r-mi-2.webp" alt="Rémi"/>
                <img className="absolute right-0 top-36 hidden md:block lg:hidden" height={600} width={400} src="https://i.ibb.co/kXG7Dnw/r-mi-2.webp" alt="Rémi"/>

            </div>
        </div>
        {/*troisième partie*/}
        <div className=" w-full md:flex md:flex-row md:justify-end">
            <div className="w-full flex flex-col-reverse lg:flex-row-reverse m-auto md:w-10/12 ">
                {/*image de fond bleu foncé*/}
                <img src={bas} className="hidden md:block bottom-0 left-0 absolute -z-10 h-[600px] md:h-[1000px] xl:h-[750px] w-full" alt=""/>
                <div className="w-10/12 lg:w-1/2 m-auto pt-12">
                    {/*zone de texte avec titre*/}
                    <h1 className=" md:text-4xl text-2xl text-bleu-ciel m-auto text-center">Nos valeurs</h1>
                    <p className="py-5 md:my-18 text-base m-auto text-justify  md:text-xl md:text-white leading-9  font-semibold">
                        Chez Azzu-informatique, nous sommes guidés par des valeurs fortes. L’honnêteté,
                        l’intégrité et la transparence sont au cœur de notre approche.
                        Nous croyons en l’établissement de relations de confiance durables avec nos clients, en
                        comprenant leurs besoins spécifiques et en fournissant des solutions
                        adaptées. Notre engagement envers la qualité se reflète dans chaque aspect de notre
                        travail. Nous accordons une attention minutieuse aux détails et nous nous
                        efforçons de dépasser les attentes de nos clients.
                        Nous sommes déterminés à créer des partenariats solides et à long terme, en plaçant la
                        satisfaction du client au centre de notre démarche.
                    </p>
                </div>
                {/*photo du client avec son employé*/}
                <img  id="equipe" src={equipe} className="w-10/12 lg:w-1/3 h-auto m-auto" alt=""/>
            </div>
        </div>
        {/*bouton CTA*/}
        <div className="py-8 flex flex-row justify-center m-auto">
            <a id="button" className=" bg-jaune2 rounded-2xl m-auto py-6 px-10 text-white font-bold text-center" href="tel:+33632513175">Contactez nous</a>
        </div>
    </div>
    <Footer/>
</div>


);
}


