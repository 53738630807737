import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import './Hardware.scss'
import fixe from "../assets/fixe.webp"
import haut from "../assets/haut.webp"
import bas from "../assets/bas.webp"
import mobile from "../assets/tablettesPortables.webp"
import pieces from "../assets/pieces.png"
import {Player} from "@lottiefiles/react-lottie-player";


export default function Hardware()   {
    return (
        <div className="body">
            <Header/>
            <div >
                <div className="w-full h-[80vh] sm:h-[60vh] md:h-[800px] relative md:flex md:flex-row md:justify-center" id="header">
                    <img className="opacity-0 md:opacity-100 absolute left-0 top-0 -z-10 w-full md:h-[850px]" src={haut} alt="fond"/>
                        <div className="w-10/12 mx-auto md:w-10/12 lg:w-10/12 my-6 font-bold md:pt-2 md:pl-8 lg:pt-0">
                            <h1 className="text-bleu-ciel text-center text-4xl py-8">Vente matériel</h1>
                            <p className="text-xl  leading-9 text-center  font-semibold ">
                                Professionnels et amateurs exigeants, découvrez notre sélection pointue de matériel de haute qualité. Chez Azzu-informatique, nous nous engageons à offrir des équipements performants et fiables, conçus pour répondre aux besoins spécifiques de chaque projet. Notre gamme, alliant innovation et robustesse, a été soigneusement sélectionnée pour garantir votre satisfaction.
                                Nous sommes là pour vous accompagner dans vos choix avec expertise et conseils personnalisés. Explorez notre catalogue et trouvez l'outil parfait pour vos travaux.
                            <br/><em className="text-2xl text-bleu-nuit font-bold">Azzu-informatique, votre partenaire de confiance pour un matériel de qualité.</em>
                            </p>
                        </div>

                </div>
                <div className="flex flex-col justify-center w-10/12 my-36 sm:mt-12 m-auto md:flex-row md:h-400px space-y-12 md:space-y-0 md:space-x-8">
                    <img src={fixe} className="w-48 h-48 md:w-45 md:h-60 lg:h-96 lg:w-96 mx-auto" alt=""/>
                    <div className="w-full md:w-8/12 lg:w-1/2 m-auto flex flex-col justify-center ">
                        <h2 className="text-center text-2xl lg:text-3xl text-bleu-ciel">Ordinateur fixe</h2>
                        <p className="text-justify text-lg lg:text-xl leading-9">Vous avez besoin d'une solution simple et efficace ? Vous aimez le confort d'un bureau traditionnel ? optez pour un ordinateur fixe</p>
                    </div>
                </div>
                <div className="flex flex-col justify-end py-8 px-20 w-full space-y-4 md:space-y-0 md:justify-center md:flex-row lg:justify-end md:px-6 lg:px-32 md:space-x-6 ">
                    <div className="w-1/2 md:w-1/4  xl:w-1/6 lg:h-48 flex flex-col justify-center bg-gray-100 rounded-xl px-4 py-6">
                        <svg className="m-auto" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M28.3636 6.5C28.0846 6.5 27.8259 6.64833 27.6848 6.89012L27.6725 6.91119L18.5 21.7815V41.5H35.6716C36.0401 41.5 36.3588 41.2442 36.4386 40.885L36.4455 40.8538L41.5455 20.9345C41.6389 20.4517 41.2689 20 40.774 20H33C31.6977 20 30.3798 19.5649 29.4204 18.6285C28.4118 17.644 27.9441 16.2341 28.2442 14.7152L29.6819 7.43808C29.7779 6.95223 29.4061 6.5 28.9113 6.5H28.3636ZM14.5 41.5V23.2143H7.41306C6.98169 23.2143 6.63071 23.5623 6.62737 23.9942C6.62737 23.9942 6.62737 23.9942 6.62737 23.9942L6.50003 40.7084C6.49667 41.1443 6.84931 41.5 7.28555 41.5H14.5ZM15.3838 19.2143L24.2442 4.84999C25.1048 3.3947 26.6705 2.5 28.3636 2.5H28.9113C31.9267 2.5 34.1905 5.25515 33.6061 8.21336L32.1684 15.4905C32.1428 15.62 32.1594 15.6788 32.1634 15.6918C32.1678 15.7062 32.1776 15.7301 32.2143 15.7659C32.2979 15.8476 32.547 16 33 16H40.774C43.8359 16 46.1098 18.8357 45.4457 21.8242L45.4387 21.8554L40.3354 41.788C39.8358 43.9593 37.9023 45.5 35.6716 45.5H7.28555C4.62846 45.5 2.47978 43.3351 2.50014 40.678C2.50014 40.6779 2.50014 40.678 2.50014 40.678L2.62749 23.9637C2.64764 21.3351 4.78407 19.2143 7.41306 19.2143H15.3838Z" fill="#1E212C"/>
                        </svg>
                        <h3 className="text-center py-4">Puissance</h3>
                        <p className="text-center">
                            La solution la plus puissante au moindre coût
                        </p>

                    </div>
                    <div className="w-full md:w-1/4  xl:w-1/6 lg:h-48 flex flex-col justify-center bg-gray-100 rounded-xl px-4 py-6">
                        <svg className="m-auto" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M4.19922 4C5.30379 4 6.19922 4.89543 6.19922 6V14.8726C9.51527 8.41856 16.2392 4 23.9992 4C32.7104 4 40.116 9.56826 42.8606 17.3335C43.2287 18.3749 42.6829 19.5176 41.6414 19.8857C40.6 20.2538 39.4574 19.7079 39.0893 18.6665C36.8918 12.4494 30.9625 8 23.9992 8C18.0791 8 12.9065 11.216 10.1391 16H16.1991C17.3037 16 18.1991 16.8954 18.1991 18C18.1991 19.1046 17.3037 20 16.1991 20H4.19922C3.09465 20 2.19922 19.1046 2.19922 18V6C2.19922 4.89543 3.09465 4 4.19922 4Z" fill="#FF5A30"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.35328 28.1143C7.39472 27.7462 8.53736 28.2921 8.90546 29.3335C11.1029 35.5506 17.0322 40 23.9955 40C29.9156 40 35.0882 36.784 37.8556 32H31.7956C30.691 32 29.7956 31.1046 29.7956 30C29.7956 28.8954 30.691 28 31.7956 28H43.7955C44.9001 28 45.7955 28.8954 45.7955 30V42C45.7955 43.1046 44.9001 44 43.7955 44C42.6909 44 41.7955 43.1046 41.7955 42V33.1274C38.4794 39.5814 31.7555 44 23.9955 44C15.2843 44 7.87872 38.4317 5.1341 30.6665C4.766 29.6251 5.31185 28.4824 6.35328 28.1143Z" fill="#FF5A30"/>
                        </svg>
                        <h3  className="text-center">Upgrade</h3>
                        <p className="text-center py-4">
                            Possibilité de faire évoluer le matériel en fonction de vos besoins
                        </p>

                    </div>
                    <div className="w-full md:w-1/4  xl:w-1/6 lg:h-48 flex flex-col justify-end md:justify-center bg-gray-100 rounded-xl px-4 py-6">
                        <svg className="m-auto" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M19.6969 41.5C19.976 41.5 20.2346 41.3517 20.3758 41.1099L20.3881 41.0888L29.5605 26.2185L29.5606 6.5L12.3889 6.5C12.0205 6.5 11.7018 6.75577 11.622 7.115L11.615 7.1462L6.51508 27.0654C6.42164 27.5482 6.79165 28 7.28658 28L15.0605 28C16.3629 28 17.6807 28.4351 18.6402 29.3715C19.6488 30.356 20.1164 31.7659 19.8163 33.2848L18.3786 40.5619C18.2826 41.0478 18.6545 41.5 19.1493 41.5L19.6969 41.5ZM33.5606 6.5L33.5605 24.7857L40.6475 24.7857C41.0789 24.7857 41.4298 24.4377 41.4332 24.0058C41.4332 24.0058 41.4332 24.0058 41.4332 24.0058L41.5605 7.29156C41.5639 6.85568 41.2112 6.5 40.775 6.5L33.5606 6.5ZM32.6768 28.7857L23.8164 43.15C22.9558 44.6053 21.3901 45.5 19.6969 45.5L19.1493 45.5C16.1339 45.5 13.87 42.7448 14.4545 39.7866L15.8922 32.5095C15.9178 32.38 15.9012 32.3212 15.8972 32.3082C15.8927 32.2938 15.883 32.2699 15.8463 32.2341C15.7626 32.1524 15.5135 32 15.0605 32L7.28658 32C4.22463 32 1.95073 29.1643 2.61489 26.1758L2.62182 26.1446L7.72523 6.21183C8.22485 4.04064 10.1583 2.5 12.3889 2.5L40.775 2.5C43.4321 2.5 45.5808 4.66486 45.5604 7.32204C45.5604 7.32209 45.5604 7.32199 45.5604 7.32204L45.4331 24.0363C45.4129 26.6649 43.2765 28.7857 40.6475 28.7857L32.6768 28.7857Z" fill="#1E212C"/>
                        </svg>
                        <h3  className="text-center py-4">Fixe</h3>
                        <p className="text-center">
                            Nécessite un encombrement et la disponibilité d'un bureau
                        </p>
                    </div>
                </div>
                <div className="mt-14 pt-4 relative w-full">
                    <img src={bas} className=" opacity-0 md:opacity-100 absolute bottom-80 left-0 h-auto w-full -z-10 md:h-[850px] md:bottom-8" alt="bas"/>
                    <div className="flex flex-col md:flex-row justify-center w-10/12 md:mt-80 m-auto pb-20 space-y-8 md:space-x-8">
                        <div className="text-left  md:w-1/3 my-auto md:mt-8">
                            <h2 className="text-2xl lg:text-3xl text-center md:text-center text-bleu-ciel">Ordinateurs portables et tablettes</h2>
                            <p className="text-black md:text-white text-lg lg:text-xl leading-9 text-justify">La solution mobilité à votre portée ! </p>
                            <p className="text-black md:text-white text-lg lg:text-xl leading-9 text-justify">Que vous soyez au bureau ou en déplacement, optez pour une solution agile et intuitive !</p>
                        </div>
                        <img src={mobile} alt="mobile" className="w-96 h-56 mx-auto"/>
                    </div>
                    <div className="flex flex-col justify-end md:justify-center lg:justify-start lg:px-32 px-20 py-8 w-full space-y-4 md:space-y-0 md:flex-row md:px-4 md:space-x-6 md:pb-12">
                        <div className="w-full md:w-1/4  xl:w-1/6 lg:h-48 flex flex-col justify-center bg-yellow-200 opacity-90 rounded-xl px-4 py-6">
                            <svg className="m-auto" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M28.3636 6.5C28.0846 6.5 27.8259 6.64833 27.6848 6.89012L27.6725 6.91119L18.5 21.7815V41.5H35.6716C36.0401 41.5 36.3588 41.2442 36.4386 40.885L36.4455 40.8538L41.5455 20.9345C41.6389 20.4517 41.2689 20 40.774 20H33C31.6977 20 30.3798 19.5649 29.4204 18.6285C28.4118 17.644 27.9441 16.2341 28.2442 14.7152L29.6819 7.43808C29.7779 6.95223 29.4061 6.5 28.9113 6.5H28.3636ZM14.5 41.5V23.2143H7.41306C6.98169 23.2143 6.63071 23.5623 6.62737 23.9942C6.62737 23.9942 6.62737 23.9942 6.62737 23.9942L6.50003 40.7084C6.49667 41.1443 6.84931 41.5 7.28555 41.5H14.5ZM15.3838 19.2143L24.2442 4.84999C25.1048 3.3947 26.6705 2.5 28.3636 2.5H28.9113C31.9267 2.5 34.1905 5.25515 33.6061 8.21336L32.1684 15.4905C32.1428 15.62 32.1594 15.6788 32.1634 15.6918C32.1678 15.7062 32.1776 15.7301 32.2143 15.7659C32.2979 15.8476 32.547 16 33 16H40.774C43.8359 16 46.1098 18.8357 45.4457 21.8242L45.4387 21.8554L40.3354 41.788C39.8358 43.9593 37.9023 45.5 35.6716 45.5H7.28555C4.62846 45.5 2.47978 43.3351 2.50014 40.678C2.50014 40.6779 2.50014 40.678 2.50014 40.678L2.62749 23.9637C2.64764 21.3351 4.78407 19.2143 7.41306 19.2143H15.3838Z" fill="#1E212C"/>
                            </svg>
                            <h3 className="text-center py-4">Mobilité</h3>
                            <p className="text-center">
                                LA solution la plus mobile pour travailler dans toutes les conditions
                            </p>

                        </div>
                        <div className="w-full md:w-1/4  xl:w-1/6 lg:h-48  flex flex-col justify-center bg-blue-200 opacity-90 rounded-xl px-4 py-6">
                            <svg className="m-auto" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M4.19922 4C5.30379 4 6.19922 4.89543 6.19922 6V14.8726C9.51527 8.41856 16.2392 4 23.9992 4C32.7104 4 40.116 9.56826 42.8606 17.3335C43.2287 18.3749 42.6829 19.5176 41.6414 19.8857C40.6 20.2538 39.4574 19.7079 39.0893 18.6665C36.8918 12.4494 30.9625 8 23.9992 8C18.0791 8 12.9065 11.216 10.1391 16H16.1991C17.3037 16 18.1991 16.8954 18.1991 18C18.1991 19.1046 17.3037 20 16.1991 20H4.19922C3.09465 20 2.19922 19.1046 2.19922 18V6C2.19922 4.89543 3.09465 4 4.19922 4Z" fill="#FF5A30"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.35328 28.1143C7.39472 27.7462 8.53736 28.2921 8.90546 29.3335C11.1029 35.5506 17.0322 40 23.9955 40C29.9156 40 35.0882 36.784 37.8556 32H31.7956C30.691 32 29.7956 31.1046 29.7956 30C29.7956 28.8954 30.691 28 31.7956 28H43.7955C44.9001 28 45.7955 28.8954 45.7955 30V42C45.7955 43.1046 44.9001 44 43.7955 44C42.6909 44 41.7955 43.1046 41.7955 42V33.1274C38.4794 39.5814 31.7555 44 23.9955 44C15.2843 44 7.87872 38.4317 5.1341 30.6665C4.766 29.6251 5.31185 28.4824 6.35328 28.1143Z" fill="#FF5A30"/>
                            </svg>
                            <h3  className="text-center">Upgrade</h3>
                            <p className="text-center py-4">
                                Possibilité de faire évoluer le matériel en fonction de vos besoins
                            </p>

                        </div>
                        <div className="w-full md:w-1/4  xl:w-1/6 lg:h-48  flex flex-col justify-center bg-yellow-200 opacity-90 rounded-xl px-4 py-6 ">
                            <svg className="m-auto" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.6969 41.5C19.976 41.5 20.2346 41.3517 20.3758 41.1099L20.3881 41.0888L29.5605 26.2185L29.5606 6.5L12.3889 6.5C12.0205 6.5 11.7018 6.75577 11.622 7.115L11.615 7.1462L6.51508 27.0654C6.42164 27.5482 6.79165 28 7.28658 28L15.0605 28C16.3629 28 17.6807 28.4351 18.6402 29.3715C19.6488 30.356 20.1164 31.7659 19.8163 33.2848L18.3786 40.5619C18.2826 41.0478 18.6545 41.5 19.1493 41.5L19.6969 41.5ZM33.5606 6.5L33.5605 24.7857L40.6475 24.7857C41.0789 24.7857 41.4298 24.4377 41.4332 24.0058C41.4332 24.0058 41.4332 24.0058 41.4332 24.0058L41.5605 7.29156C41.5639 6.85568 41.2112 6.5 40.775 6.5L33.5606 6.5ZM32.6768 28.7857L23.8164 43.15C22.9558 44.6053 21.3901 45.5 19.6969 45.5L19.1493 45.5C16.1339 45.5 13.87 42.7448 14.4545 39.7866L15.8922 32.5095C15.9178 32.38 15.9012 32.3212 15.8972 32.3082C15.8927 32.2938 15.883 32.2699 15.8463 32.2341C15.7626 32.1524 15.5135 32 15.0605 32L7.28658 32C4.22463 32 1.95073 29.1643 2.61489 26.1758L2.62182 26.1446L7.72523 6.21183C8.22485 4.04064 10.1583 2.5 12.3889 2.5L40.775 2.5C43.4321 2.5 45.5808 4.66486 45.5604 7.32204C45.5604 7.32209 45.5604 7.32199 45.5604 7.32204L45.4331 24.0363C45.4129 26.6649 43.2765 28.7857 40.6475 28.7857L32.6768 28.7857Z" fill="#1E212C"/>
                            </svg>
                            <h3  className="text-center py-4">Fixe</h3>
                            <p className="text-center">
                                Au même niveau de puissance qu'un PC fixe, son coût sera supérieur
                            </p>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
                <div className="flex flex-col md:flex-row justify-center w-8/12 m-auto py-24 h-[700px] md:h-[400px]">
                    <img src={pieces} className="w-44 h-60 md:my-auto my-8 mx-auto" alt=""/>
                    <div className="m-auto">
                        <h2 className="text-2xl text-center text-bleu-ciel">Pièces détachées</h2>
                        <p className="text-lg text-justify">Sapien, feugiat faucibus orci arcu, vulputate. Tristique varius consectetur vulputate arcu, scelerisque nisi, nibh. Enim semper id sodales ultricies sed ut ut
                            augue. Mattis habitant venenatis, gravida posuere massa ac interdum. Eget aliquam dignissim ut vestibulum. </p>
                    </div>

                </div>
            </div>
            <Footer/>
        </div>
    );

}

