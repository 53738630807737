import React, {useEffect, useState} from 'react';
import './Carousel.scss';
import {useSwipeable} from "react-swipeable";
import security from '../assets/security2.svg'
import maintenance from '../assets/maintenance2.svg'
import materiel from '../assets/materiel2.svg'


const items = [
    {
        id: 'maintenance',
        title: 'Gestion et maintenance',
        content: 'Gestion de parc informatique simplifiée. Maîtrisez votre infrastructure, optimisez vos ressources.',
        imageUrl: maintenance,
        link: '/it'
    },
    {
        id: 'materiel',
        title: 'Vente de matériel',
        content: 'Matériel informatique de qualité et expertise assurée : choisissez Azzu. Optimisez votre équipement, boostez votre productivité.',
        imageUrl: materiel,
        link: '/materiel'
    },
    {
        id: 'securite',
        title: 'Logiciel et sécurité',
        content: 'La combinaison parfaite avec Azzu-informatique. Protégez vos données, optimisez vos performances.',
        imageUrl: security,
        link: '/logiciel'
    },
];

export default function Carousel() {
    const [activeIndex, setActiveIndex] = useState(1);

    const goToPrevious = () => {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
    };

    const goToNext = () => {
        setActiveIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
    };

    useEffect(() => {
        const interval = setInterval(() => {
            goToNext(); // Fonction qui met à jour l'index actif
        }, 4000); // Change toutes les 4 secondes

        return () => clearInterval(interval); // Nettoie l'intervalle lors du démontage du composant
    }, [activeIndex]);

    const handlers = useSwipeable({
        onSwipedLeft: () => goToNext(),
        onSwipedRight: () => goToPrevious(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });
    const handleIndicatorClick = (index) => () => {
        console.log("Indicator clicked: ", index);
        setActiveIndex(index);
    };

    return (
        <div className="carousel-container mt-24 md:mt-64 lg:mt-80">
            <div {...handlers} className="carousel flex flex-col m-auto " >
                {items.map((item, index) => (
                    <div

                        key={item.id}
                        className={`w-full rounded-lg carousel-item ${index === activeIndex ? 'center' : index === activeIndex - 1 || (activeIndex === 0 && index === items.length - 1) ? 'left' : 'right'}`}
                    >
                        {/* Contenu de la carte */}
                        <div className=" w-full flex flex-col justify-between space-y-2  ">
                            <img src={item.imageUrl} height={90} width={90} className="hidden lg:block m-auto" alt="icon"/>
                            <img src={item.imageUrl} height={60} width={60} className=" lg:hidden m-auto" alt="icon"/>
                            <h2 className="sm:text-xl md:text-4xl text-bleu-fonce text-center font-bold m-auto h-auto py-1">{item.title}</h2>
                        </div>
                        <div className="w-full py-2">
                            <svg width="100%" height="10">
                                <line x1="25%" y1="5" x2="75%" y2="5" stroke="#FCBF12" strokeWidth="5" />
                            </svg>
                        </div>

                        <div className="p-4 w-full flex flex-col  md:flex-row justify-between ">
                            <div className="w-10/12 md:w-2/3 m-auto">
                                <p className="text-center text-base md:text-2xl">{item.content}</p>
                            </div>
                            <div className="m-auto p-4  ">
                                <a href={item.link} className="px-4 py-3 md:px-6 md:py-4 text-bleu-fonce font-bold shadow-xl bg-jaune2 rounded-lg shadow-jaune">Découvrir</a>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
            <button className="carousel-button prev-button" onClick={goToPrevious}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                    <path fillRule="evenodd"
                          d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
                          clipRule="evenodd"/>
                </svg>
            </button>
            <button className="carousel-button next-button" onClick={goToNext}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                    <path fillRule="evenodd"
                          d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
                          clipRule="evenodd"/>
                </svg>
            </button>
            <div className="carousel-progress-indicators flex flex-row justify-center space-x-2 m-auto pt-3 md:pt-24 ">
                {items.map((_, index) => (
                    <div
                        key={index}
                        className={`progress-indicator cursor-pointer ${index === activeIndex ? 'active' : ''}`}
                        onClick={handleIndicatorClick(index)}
                    />
                ))}
            </div>
        </div>
    );
}
