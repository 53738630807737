import React, { useState, useEffect } from 'react';
import "./Cookies.scss"
import {Link} from "react-router-dom";

const CookieConsent = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const cookieConsentValue = localStorage.getItem('cookieConsent');
        if (cookieConsentValue === null) {
            setShowBanner(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setShowBanner(false);
        // Activer les cookies non essentiels ici si nécessaire
    };

    const handleReject = () => {
        localStorage.setItem('cookieConsent', 'false');
        setShowBanner(false);
        // Désactiver les cookies non essentiels ici si nécessaire
    };

    if (!showBanner) return null;

    return (
        <div className="cookie-consent-container">
            <div className="cookie-consent-content">
                <div className="m-auto">
                    <p className="m-auto">Nous utilisons des cookies pour optimiser notre site web et notre service. Les cookies essentiels sont déjà activés, mais nous avons besoin de votre consentement pour les cookies non essentiels.</p>
                    <Link className="p-2 text-sm font-bold hover:text-jaune2">En savoir plus</Link>
                </div>

                <div className="cookie-consent-buttons">
                    <button onClick={handleAccept}>Accepter</button>
                    <button onClick={handleReject}>Refuser</button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;
