import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ItManagement from "./Components/ItManagement";
import Home from "./Components/Home";
import Universe from "./Components/Universe";
import Software from "./Components/Software";
import Hardware from "./Components/Hardware";
import Conditions from "./Components/Cgv";


export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Home />}/>
                <Route path='It'  element={<ItManagement />} />
                <Route path='logiciel'  element={<Software />} />
                <Route path="materiel" element={<Hardware/>} />
                <Route path='Univers'  element={<Universe />} />
                <Route path='Conditions' element={<Conditions />} />
            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
