import * as React from 'react';

import {Link} from "react-router-dom";
import {Navbar, NavbarCollapse} from 'flowbite-react';
import {useState} from "react";

const links = [
    { href: '/materiel', label: 'Matériel' },
    { href: '/logiciel', label: 'Logiciel' },
    { href: '/telephonie', label: 'Téléphonie' },
    { href: '/it', label: 'IT management' },
    { href: '/contact', label: 'Contact' },
    {href: '/', label: 'Accueil'},
    {href: '/univers', label: 'L\'univers d\'azzu'},
    {href: '/conditions', label: 'conditions'}
]
export default function Header() {


    const [isOpen, setIsOpen] = useState(true)

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    }


    return (
        <header className="bg-bleu-nuit top-0 flex flex-col  lg:flex-row justify-between py-4 2xl:text-2xl text-jaune shadow-lg lg:px-2">
            {/*Left*/}
            <div className="cursor-pointer pb-3 mx-auto md:my-auto">
                <Link to="/">
                    <img className="mt-2 " src="https://i.ibb.co/vVd9HWH/logo.png" alt="Logo"
                         height='150px'
                         width='150px'
                    />
                </Link>

            </div>
            {/*Middle*/}
            <nav className="m-auto">
                <div className="w-full flex flex-wrap items-center justify-between mx-auto p-4 my-auto">
                    <button data-collapse-toggle="navbar-dropdown" type="button"
                            className="inline-flex m-auto items-center p-2 w-10 h-10 justify-center text-sm text-gray-500
                            rounded-lg border border-yellow-300 md:hidden focus:outline-none"
                            aria-controls="navbar-dropdown"
                            aria-expanded={isOpen}
                            onClick={toggleMenu}>

                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5 text-yellow-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 17 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M1 1h15M1 7h15M1 13h15"/>
                        </svg>
                    </button>
                    <div className={`w-full md:block md:w-auto m-auto ${isOpen ? 'hidden' : 'block' }`} id="navbar-dropdown">
                        <ul className="flex flex-col  text-base  md:font-medium  md:p-0   rounded-lg md:flex-row md:space-x-16
                        md:mt-0  text-yellow-300 m-auto text-jaune2">
                            <li>
                                <a href="../it" className="block py-2 pl-3 pr-4 rounded md:border-0 md:p-0 text-jaune2 ">Services</a>
                            </li>
                            <li>
                                <a href="../logiciel" className="block py-2 pl-3 pr-4 rounded md:border-0 md:p-0 text-jaune2">Logiciel et sécurité</a>
                            </li>
                            <li>
                                <a href="../materiel" className="block py-2 pl-3 pr-4 rounded md:border-0 md:p-0 text-jaune2">Vente de matériel</a>
                            </li>
                            <li>
                                <a href="../univers" className="block py-2 pl-3 pr-4 rounded md:border-0 md:p-0 text-jaune2 ">Notre entreprise</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="hidden lg:block lg:w-auto lg:flex lg:items-end lg:justify-end lg:m-auto md:text-gray-500 pr-16">
                <button className="text-red-700 bg-gray-200 px-8 py-2 rounded-full shadow-md font-bold  hover:shadow-xl
                active:scale-90 transition duration-150 z-20 ">Assistance</button>
            </div>
        </header>
    );
};
