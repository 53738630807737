import React, { useEffect, useState } from 'react';
import './Partners.scss'; // Assure-toi que le nom du fichier SCSS correspond
import { useSwipeable } from "react-swipeable";
import Fichier1 from '../assets/svg/Fichier1.svg'
import Fichier2 from '../assets/svg/Fichier2.svg'
import Fichier3 from '../assets/svg/Fichier3.svg'
import Fichier4 from '../assets/svg/Fichier4.svg'
import Fichier5 from '../assets/svg/Fichier5.svg'
import Fichier6 from '../assets/svg/Fichier6.svg'
import Fichier7 from '../assets/svg/Fichier7.svg'
import Fichier8 from '../assets/svg/Fichier8.svg'
import Fichier9 from '../assets/svg/Fichier9.svg'
import Fichier10 from '../assets/svg/Fichier10.svg'
import Fichier11 from '../assets/svg/Fichier11.svg'
import Fichier12 from '../assets/svg/Fichier12.svg'
import Fichier13 from '../assets/svg/Fichier13.svg'
import Fichier14 from '../assets/svg/Fichier14.svg'
import Fichier15 from '../assets/svg/Fichier15.svg'


const items = [
    {
        imageUrl: Fichier1 ,
    },
    {
        imageUrl: Fichier2 ,
    },
    {
        imageUrl: Fichier3 ,
    },
    {
        imageUrl:Fichier4 ,
    },
    {
        imageUrl: Fichier5 ,
    },
    {
        imageUrl: Fichier6 ,
    },
    {
        imageUrl: Fichier7 ,
    },
    {
        imageUrl: Fichier8 ,
    },
    {
        imageUrl: Fichier9 ,
    },
    {
        imageUrl: Fichier10 ,
    },
    {
        imageUrl: Fichier11 ,
    },
    {
        imageUrl: Fichier12 ,
    },
    {
        imageUrl: Fichier13 ,
    },
    {
        imageUrl: Fichier14,
    },
    {
        imageUrl: Fichier15 ,
    },
];

const calculScreenWidth = () => {
    if (typeof window !== 'undefined') {
        return window.innerWidth;
    }
    return 0;
}


const PartnerCarousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const visibleItems = 5;


    const goToPrevious = () => {
        setActiveIndex(prevIndex => {
            // Si on est au début, aller à la fin en prenant en compte le nombre total d'items % visibleItems
            return prevIndex - visibleItems < 0 ? items.length - (items.length % visibleItems) : prevIndex - visibleItems;
        });
    };

    const goToNext = () => {
        setActiveIndex(prevIndex => {
            // Si on est à la fin ou que l'ajout de visibleItems dépasse la longueur, revenir à 0
            return prevIndex + visibleItems >= items.length ? 0 : prevIndex + visibleItems;
        });
    };


    useEffect(() => {
        const interval = setInterval(goToNext, 4000);
        return () => clearInterval(interval);
    }, [activeIndex]);

    const handlers = useSwipeable({
        onSwipedLeft: goToNext,
        onSwipedRight: goToPrevious,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <div className="partner-carousel-container  ">
            <h2 className="text-xl text-center text-bleu-fonce p-4 md:text-4xl">Nos partenaires</h2>
            <div {...handlers} className="partner-carousel">
                {items.slice(activeIndex, activeIndex + visibleItems).map((item) => (
                    <img
                        key={item.imageUrl}
                        src={item.imageUrl}
                        alt="Partner"
                        className="partner-carousel-item -z-10"
                    />
                ))}
            </div>
        </div>
    );
};

export default PartnerCarousel;
