import * as React from 'react';
import ReactPlayer from "react-player";
import video  from "../assets/video.mp4";
import video2  from "../assets/video2.mp4";
import {useEffect, useState} from "react";
import "./Video.scss"
import "../App.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export default function Video() {
    const [isVisible, setIsVisible] = useState(true);
        useEffect(() => {
            const toggleVisibility = () => {
                setIsVisible(window.scrollY === 0);
            };

            // Ajouter l'écouteur d'événement lors du montage du composant
            window.addEventListener('scroll', toggleVisibility);

            // Enlever l'écouteur d'événement lors du démontage du composant
            return () => window.removeEventListener('scroll', toggleVisibility);
        }, []);

    return (
        <div className="relative">
            <div id="video" className='hidden md:block w-full h-auto relative opacity-90 z-20'>
                <ReactPlayer
                    className=''
                    url={video}
                    controls={false}
                    playing={true}
                    muted={true}
                    width='100%'
                    height='100%'
                    loop={true}
                />
            </div>
            <div id="video" className='md:hidden w-full h-auto relative opacity-90 z-20'>
                <ReactPlayer
                    className=''
                    url={video2}
                    controls={false}
                    playing={true}
                    muted={true}
                    width='100%'
                    height='100%'
                    loop={true}
                />
            </div>
            <div id="hero" className="w-full border-gray-100  flex flex-col md:flex-row my-auto py-20 space-x-24 absolute top-0 left-0 right-0 bottom-0 z-40">
                <div className=" w-full md:w1/2 lg:w-1/3 flex flex-row justify-start my-auto md:pl-40">
                    <h1 className="absolute w-1/3 top-1/4 md:-left-24  -lg:left-5 lg:top-5 md:relative  text-left text-4xl lg:text-6xl font-bold text-bleu text-jaune2 px-4 py-4 ">Changez votre vision de l'informatique d'entreprise</h1>
                </div>
                <div>

                </div>
            </div>
            <div id="filter" className=" absolute hidden md:block  z-50 top-0 left-0 right-0 bottom-0  ">
                <a id="scroll" href="#anchor" className={`fixed bottom-0 rounded-full left-1/2 px-10 py-4 bg-bleu-nuit text-white text-center cursor-pointer ${!isVisible ? 'hidden' : 'block'}`}
                    // Ajouter la condition pour afficher le bouton uniquement si isVisible est true
                >Scroll</a>
                </div>
            <div id="filter" className=" absolute hidden md:block bg-jaune z-40 top-0 left-0 right-0 bottom-0 opacity-10 ">

            </div>
        </div>



    );
}

