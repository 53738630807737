import React, {useEffect} from 'react';
import Header from "./Header";
import Footer from "./Footer";
import haut from '../assets/haut.webp'
import bas from '../assets/bas.webp'
import IT from '../assets/Projet IT.webp'
import serveur from '../assets/gestion de parc.webp'
import quote from '../assets/citation.webp'
import quote2 from '../assets/citation2.webp'
import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { TextPlugin } from "gsap/TextPlugin";

import {useGSAP} from "@gsap/react";
import {isMobile} from "react-device-detect";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, TextPlugin);


export default function Itmanagement()  {

    React.useEffect(() => {
        console.log(isMobile)
        if (!isMobile) {

        const tl = gsap.timeline();

        // Ajoutez des animations à la timeline
        tl.from('#fond', {opacity: 0, y: -400, duration: 0.5})
            .from("#it h1", {opacity: 0, x: -200, y: -200, duration: 0.7})
            .from("#it p", {opacity: 0, x: -100, y: -100, duration: 0.4})

        gsap.from("#projetIT h2", {
            opacity: 0,
            x: -200,
            scrollTrigger: {
                trigger: "#projetIT h2", // Assure-toi que le sélecteur de trigger est correct
                start: "top 90%",
                end: "top 100px",
            },
            ease: "power3.out",
        });

        gsap.from("#projetIT p", {
            opacity: 0,
            x: -400,
            duration: 1,
            scrollTrigger: {
                trigger: "#projetIT p", // Assure-toi que le sélecteur de trigger est correct
                start: "top 90%",
                end: "top 100px",
            },
            ease: "power3.out",
        });

        gsap.from("#gestion h2", {
            opacity: 0,
            x: 200,
            duration: 1,
            scrollTrigger: {
                trigger: "#gestion", // Assure-toi que le sélecteur de trigger est correct
                start: "top 90%",
                end: "top 100px",
            },
            ease: "power3.out",
        });

        gsap.from("#gestion p", {
            opacity: 0,
            x: 400,
            duration: 1,
            scrollTrigger: {
                trigger: "#gestion", // Assure-toi que le sélecteur de trigger est correct
                start: "top 90%",
                end: "top 100px",
            },
            ease: "power3.out",
        });

        gsap.from("#maintenance h2", {
            opacity: 0,
            x: -200,
            duration: 1,
            scrollTrigger: {
                trigger: "#maintenance", // Assure-toi que le sélecteur de trigger est correct
                start: "top 90%",
                end: "top 100px",
            },
            ease: "power3.out",
        });
        gsap.from("#maintenance p", {
            opacity: 0,
            x: -400,
            duration: 1,
            scrollTrigger: {
                trigger: "#maintenance", // Assure-toi que le sélecteur de trigger est correct
                start: "top 90%",
                end: "top 100px",
            },
            ease: "power3.out",
        });

        gsap.from("#blockQuote", {
            opacity: 0,
            rotateY: -180,
            duration: 1.5,
            scrollTrigger: {
                trigger: "#blockQuote", // Assure-toi que le sélecteur de trigger est correct
                start: "top 90%",
                end: "top 100px",
            },
            ease: "power3.out",
        });


            gsap.from("#button", {
                opacity: 0,
                rotateY: 180,
                scrollTrigger: {
                    trigger: "#button",
                    start: "top 90%",
                    end: "top 100%",
                    // scrub: 1,
                },
                ease: "power3.out",
                duration: 1.5,

            });
    }
} ,[])



    return (
        <div className="body h-full">
            <Header/>
            <div  className="w-full m-auto relative">
                <img id='fond' src={haut} alt="fond haut" className="hidden sm:block absolute top-0 left-0 -z-10 lg:h-[800px] h-[600px] w-full"/>
                <div className="lg:w-10/12 xl:w-8/12  flex flex-col md:flex-row  py-20 m-auto">
                    <div  id="it">
                        <h1 className=" p-5 text-2xl md:text-4xl text-bleu-ciel font-bold text-center ">Management IT</h1>
                        <p className=" p-5 w-full text-sm text-justify md:w-8/12 m-auto md:text-xl  font-semibold">Chez Azzu informatique, nous sommes déterminés à offrir un service d'IT management de première classe.
                            Que vous ayez besoin d'accompagnement pour un projet, d'une assistance ponctuelle pour résoudre un problème ou que vous souhaitiez mettre en place un plan de gestion à
                            long terme, nous sommes là pour vous accompagner. Faites confiance à notre équipe d'experts passionnés pour prendre soin de votre parc informatique,
                            vous conseiller au plus juste et vous fournir des solutions fiables qui correspondent à vos besoins.</p>
                    </div>

                </div>
                <div className="relative">
                    <div id="projetIT" className="xl:w-8/12 lg:w-10/12 w-full flex flex-col-reverse md:flex-row m-auto lg:mt-48 my-12 xl:mt-64 lg:space-x-24">
                        <div >
                            <img src={bas} className='hidden md:block absolute bottom-0 left-0 w-full md:h-[980px] lg:h-[850px] xl:h-[600px] -z-10' alt=""/>
                            <div className="m-auto">
                                <h2 className="p-5 text-center md:text-left text-2xl lg:text-4xl py-2 text-bleu-ciel font-bold">Projet IT</h2>
                                <p  className=" p-5 w-full text-sm  m-auto text-justify md:text-lg  font-semibold">
                                    Spécialisée dans l'accompagnement de projets d'infrastructure IT, notre société met à votre service une expertise inégalée pour vous guider à chaque étape de votre parcours
                                    technologique. Notre équipe expérimentée, dédiée à la réussite de votre projet, vous assiste dans la mise en place de serveurs, solutions collaboratives ou restructurations pour
                                    augmenter votre productivité. Nous élaborons des solutions sur mesure, en adéquation avec vos objectifs et besoins, maximisant efficacité, sécurité et rentabilité.
                                    Avec nous, votre projet IT est géré de manière transparente et efficace, toujours avec un engagement fort envers la satisfaction client et la réalisation de vos ambitions stratégiques.
                                </p>
                            </div>
                        </div>
                        <img src={IT} className="w-64 h-48 lg:w-96 lg:h-72 md:my-auto m-auto" alt=""/>

                    </div>
                    <div id="gestion" className="flex flex-col md:flex-row xl:w-8/12 lg:w-10/12 mx-auto md:my-12 lg:my-24 lg:space-x-24">
                        <img src={serveur} className="w-48 h-60 lg:w-80 lg:h-96 m-auto" alt="man using a computer"/>
                        <div className="py-5 text-right">
                            <h2 className="p-5 text-center md:text-right text-2xl lg:text-4xl text-bleu-ciel py-2 text-amber-500 font-bold">Gestion</h2>

                            <p className="text-right p-5 w-full text-sm text-justify m-auto  md:text-lg  font-semibold">La gestion efficiente de votre parc informatique revêt une importance capitale dans le maintien de la productivité à long terme de votre entreprise. Notre service de gestion planifiée vous
                                offre la tranquillité d'esprit en assurant la surveillance proactive de votre infrastructure informatique, la mise à jour régulière des logiciels, la réalisation de sauvegardes périodiques,
                                et bien d'autres prestations essentielles. Nous collaborons étroitement avec vous pour appréhender vos besoins spécifiques et élaborer un plan sur mesure parfaitement adapté à votre entreprise. Notre approche méthodique et
                                réfléchie garantit que votre parc informatique demeure constamment à jour, sécurisé et performant.
                            </p>
                        </div>

                    </div>
                    <div className='xl:w-10/12 lg:w-10/12 flex flex-col md:flex-row mx-auto md:space-x-2 lg:space-x-24 justify-center'>
                        <div id="maintenance"  className="py-5">
                            <h2 className="p-5 text-center text-bleu-ciel lg:text-4xl md:text-left text-2xl py-2 text-amber-500 font-bold">Maintenance</h2>

                            <p className="text-right md:text-white p-5 w-full text-sm text-justify  m-auto  md:text-lg  font-semibold">
                                Les dysfonctionnements informatiques sont susceptibles de se manifester à tout moment, pouvant potentiellement perturber le bon déroulement de vos opérations.
                                Notre équipe réactive se tient prête à vous assister dans la résolution rapide de ces problèmes, qu'il s'agisse de défaillances matérielles, de dysfonctionnements logiciels ou de
                                soucis liés au réseau.
                                Forte de son expertise approfondie et de son approche méthodique, notre équipe procèdera à un diagnostic précis et résoudra efficacement vos problèmes informatiques, réduisant
                                ainsi au minimum les temps d'indisponibilité et vous permettant de reprendre vos activités sans délai.
                            </p>
                        </div>
                        <div id='blockQuote' className="bg-gray-200 h-auto text-justify mx-8 md:my-auto rounded-lg p-5 relative">
                            <img src={quote2} className="absolute w-12 h-12  -top-4 left-0" alt=""/>
                            <p className="text-bleu-nuit font-extrabold text-lg">
                                Forts de notre expertise approfondie et de notre approche méthodique, nous procédons à un diagnostic précis afin de résoudre efficacement vos problèmes
                                informatiques, réduisant ainsi au minimum les temps d'indisponibilité et vous permettant de reprendre vos activités sans délai</p>
                            <img src={quote} className="absolute w-12 h-12 -bottom-4 right-0" alt=""/>
                        </div>
                    </div>
                    <div className="py-8 flex flex-row justify-center m-auto">
                        <a id="button" className="  bg-jaune2 rounded-2xl m-auto py-6 px-10 text-white font-bold text-center" href="tel:+33632513175">Contactez nous</a>
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    );
}

